import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import FeatureSectionLight from '../components/featureSectionLight';
import SubLayout from '../layouts/subLayout';
import AllServices from '../components/allServices';
import FeatureCardsSection from '../components/featureCardsSection';
import Wolne from '../../assets/svg/wolne.svg';
import Branzowe from '../../assets/svg/branzowe.svg';
import Modularna from '../../assets/svg/modularna.svg';
import SEO from '../components/seo';

const OdooPage = () => {
  const data = useStaticQuery(graphql`
        query OdooImages {
            odoo: file(
                relativePath: { eq: "interfejs-uzytkownika-odoo-1.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kompleksowaodoo: file(
                relativePath: { eq: "kompleksowa-usluga.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
  return (
    <SubLayout
      title={(
        <>
          Wdrożenia
          {' '}
          <span>Odoo</span>
        </>
              )}
      subtitle="Nowoczesne oprogramowanie ERP"
      img="/img/bg-systemy-erp.jpg"
    >
      <SEO title="Wdrożenia ERP Odoo - iqcode Software House" description="Odoo to system ERP nowej generacji. Przeprowadzamy kompleksowe wdrożenia i świadczymy usługi wsparcia technicznego oprogramowania Odoo." />
      <FeatureSectionLight
        mb0
        title={(
          <>
            <span>Odoo</span>
            {' '}
            - ERP nowej generacji
          </>
                  )}
        img={(
          <Img
            alt="wdrożenia oprogramowania Odoo ERP"
            fluid={data.odoo.childImageSharp.fluid}
          />
                )}
        desc={(
          <>
            <p>
              Odoo to wolne oprogramowanie ERP nowej generacji,
              które łączy w sobie bardzo rozbudowaną
              funkcjonalność z niezwykle prostą obsługą.
            </p>
            <p>
              Odoo jest jednym z najszybciej rozwijających się
              systemów ERP, który swoją funkcjonalnością i
              elastycznością dorównuje a nawet niekiedy przewyższa
              najczęściej stosowane rozwiązania takie jak: MS
              Dynamics, SAP czy Sage.
            </p>
          </>
                  )}
      />

      <FeatureCardsSection
        title={(
          <>
            Przewagi
            {' '}
            <span>Odoo</span>
          </>
                  )}
        subtitle="Możliwości systemu ERP nowej generacji"
        cards={[
          {
            title: (
              <>
                Wolne
                <br />
                Oprogramowanie
              </>
            ),
            icon: <Wolne />,
            desc:
                            'Odoo to wolne oprogramowanie open source, dzięki czemu klient nie ponosi kosztów związanych z zakupem licencji, a firma wdrożeniowa może je dowolnie modyfikować tak, aby sprostać oczekiwaniom klienta.',
          },
          {
            title: (
              <>
                Rozszerzenia
                <br />
                Branżowe
              </>
            ),
            icon: <Branzowe />,
            desc: (
              <>
                W ekosystemie Odoo dostępne są liczne
                rozszerzenia branżowe najczęściej dystrybuowane
                za relatywnie niewielką opłatą, co istotnie
                obniża koszt wdrożenia.
              </>
            ),
          },
          {
            title: (
              <>
                Modularna
                <br />
                Budowa
              </>
            ),
            icon: <Modularna />,
            desc:
                            'Interfejs użytkownika Odoo zawdzięcza swoją prostotę intuicyjnemu podziałowi na powiązane ze sobą aplikacje. Taka modularna budowa systemu pozwala na jego łatwą rozbudowę.',
          },
        ]}
      />
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Chcesz wycenić wdrożenie
            {' '}
            <span>odoo</span>
            ?
          </h3>
          <Link className="btn" to="/kontakt/">
            Porozmawiajmy
          </Link>
        </div>
      </div>
      <FeatureSectionLight
        mb0
        title={(
          <>
            <span>Kompleksowa </span>
            Usługa
          </>
                  )}
        img={(
          <div style={{ padding: '10%' }}>
            <Img
              alt="Kompleksowe wdrożenia systemów ERP"
              fluid={data.kompleksowaodoo.childImageSharp.fluid}
              imgStyle={{ width: 'auto' }}
            />
          </div>
                  )}
        desc={(
          <>
            <p>
              Przeprowadzamy liczne integracje oprogramowania Odoo
              z innymi produktami cyfrowymi takimi jak strony
              internetowe, sklepy internetowe czy aplikacje
              mobilne.
            </p>
            <p>
              Szeroki wachlarz świadczonych przez nas usług oraz
              nastawienie na długotrwałą współpracę z klientem
              umożliwia nam prowadzenie kompleksowej obsługi
              informatycznej przedsiębiorstw.
            </p>
          </>
                  )}
        link="Porozmawiajmy"
        to="/kontakt/"
      />
      <AllServices />
    </SubLayout>
  );
};

export default OdooPage;
